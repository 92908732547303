import request from './request'

//微信回调登录
export const lf_wx_login_request = p => {
    return request({
        method:'get',
        url:'lf_wx_login',
        params: p
    })
}

//获取验证码
export const lf_login_yzm_request = p => {
    return request({
        method:'get',
        url:'car_driver/get_yzm',
        params: p
    })
}

//司机注册
export const lf_register_request = d => {
    return request({
        method:'post',
        url:'car_driver/register',
        data: d
    })
}

//获取用户个人信息
export const driver_information_request = () => {
    return request({
        method:'GET',
        url:'car_driver/information'
    })
}

//修改用户个人资料
export const driver_edit_request = d => {
    return request({
        method:'PUT',
        url:'car_driver/edit',
        data: d
    })
}

//修改用户证件照
export const driver_card_request = d => {
    return request({
        method:'PUT',
        url:'car_driver/card',
        data: d
    })
}

//获取修改个人信息验证码
export const lf_edit_yzm_request = p => {
    return request({
        method:'get',
        url:'car_driver/get_edit_yzm',
        params: p
    })
}

//获取修改个人证件照验证码
export const lf_card_yzm_request = () => {
    return request({
        method:'get',
        url:'car_driver/get_card_yzm'
    })
}