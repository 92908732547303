<template>
    <div>
        <loading-component></loading-component>
        {{msg}}
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'

import { lf_wx_login_request } from '@/network/user'

export default {
    name:'WXLoginView',
    data(){
        return {
            msg: ''
        }
    },
    computed:{},
    methods:{
        on_login() {
            this.$store.commit('true_loading')
            lf_wx_login_request({ code: this.$route.query.code})
                    .then((s) => {
                        if (s.status === 0) {
                            this.$router.replace({ path: '/' })
                        } else {
                            this.$store.commit('edit_identity', s.result.identity)
                            this.msg = '即将为您跳转到注册页面进行注册'
                            this.$toast.fail(s.msg)
                            
                            setTimeout(() => { 
                                this.$router.replace({ path: '/register' })
                            }, 2000);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg);
                    })
                    .finally(() => this.$store.commit('false_loading'))
        }
    },
    filters:{},
    props:{},
    created(){
        this.on_login()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent
    },
    watch:{}
}
</script>

<style>
</style>